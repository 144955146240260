import React from "react";
import { HashRouter, Route, Redirect, Switch } from "react-router-dom";

import Orders from "./pages/Orders";
import NewOrder from "./pages/NewOrder";
import Login from "./pages/Login";
import Panel from "./pages/Panel";
import OrderItems from "./pages/OrderItems";
import Print from "./pages/Print";
import Report from "./pages/Report";
import Stock from "./pages/Stock";
import Axios from "./Axios";

export default class Routes extends React.Component {
  state = {
    user: null,
  };

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.validateToken();
    }
  }

  async validateToken() {
    await Axios.get("/auth/me").then((res) => {
      if (res.data.id) this.setState({ user: res.data });
    }).catch({});
  }

  render() {
    return (
      <HashRouter>
        {!localStorage.getItem("token") && !this.state.user ? (
          <Switch>
            <Route exact path="/panel" component={Panel} />
            <Route path="/" component={Login} />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/" component={Orders} />
            <Route exact path="/print/:pedido" component={Print} />
            <Route
              exact
              path="/pedido"
              component={(props) => <NewOrder {...props} clients={false} />}
            />
            <Route
              exact
              path="/pedido-expresso"
              component={(props) => (
                <NewOrder {...props} clients={false} express={true} />
              )}
            />
            <Route
              exact
              path="/clients"
              component={(props) => <NewOrder {...props} clients={true} />}
            />
            <Route
              exact
              path="/pedido/:pedido/:item"
              component={(props) => <NewOrder {...props} clients={false} />}
            />
            <Route exact path="/panel" component={Panel} />
            <Route
              exact
              path="/login"
              render={() => <Redirect to="/pedido"/>}
            />
            <Route exact path="/" component={Orders} />
            <Route exact path="/cardapio" component={OrderItems} />
            <Route exact path="/relatorio" component={Report} />
            <Route exact path="/estoque" component={Stock} />
            <Route component={Orders} />
          </Switch>
        )}
      </HashRouter>
    );
  }
}
