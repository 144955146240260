import axios from "axios";
import { authHeader } from "./helpers/authHeader";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: authHeader(),
});

export default Axios;
