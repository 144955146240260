import React, { useState, useEffect, createContext, useContext } from 'react';
import Axios from '../Axios';
import axios from 'axios';

const StockContext = createContext({});

const isAuthenticated = () => Boolean(localStorage.getItem("token"))


export function StockProvider({ children }) {
  const [breads, setBreads] = useState([]);
  const [isOrder, setIsOrder] = useState(false);
  

  async function getBreadStock() {
    if (!isAuthenticated()) return


    try {
      const response = await Axios.get('/ingredients/breads/stock')
      
      setBreads(response.data)

      return response
    } catch(error) {
      if (axios.isAxiosError(error)) {
        console.log(error)
      }
    }

  }
  
  useEffect(() => {
    setInterval(() => getBreadStock(), 60 * 4 * 1000)
  }, []);

  useEffect(() => {
    setTimeout(() => getBreadStock(), 5000);
  }, [isOrder]);

  const updateBreads = (quantityValues) => {
    const updatedBreads = [...breads];

    breads.forEach((bread, index) => {
      if (bread.quantity !== quantityValues[bread.name]) {
        Axios.put(`/ingredients/${bread.id}`, {
          quantity: quantityValues[bread.name]
        });

        updatedBreads[index]['quantity'] = quantityValues[bread.name];
      }
    })
    setBreads(updatedBreads);
    alert('Quantidades atualizadas com sucesso');
  }

  return (
    <StockContext.Provider
      value={{
        breads,
        updateBreads,
        isOrder,
        setIsOrder
      }}
    >
      {children}
    </StockContext.Provider>
  )
}

export { StockContext };

export function useStock() {
  const context = useContext(StockContext);

  return context;
}