import React from 'react';

import styled from 'styled-components';



const BreadCounterContainer = styled.div`
  background: ${props => {
    if (props.status === "great") return;
    else if (props.status === "alert") return "#EEBA1A";
    else return "#EB3131"
  }};
  border: ${props => props.status === "great" ? "0.75px solid #979797" : ""};
  min-width: 75px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 8px;
  margin-left: auto;

  p {
    font-size: 14px;
    font-weight: 400;
    color: ${props => props.status === "great" ? "#21201F" : "#FFFFFF"};
  }

  svg path {
    fill: ${props => props.status === "great" ? "#21201F" : "#FFFFFF"};
    stroke: ${props => props.status === "great" ? "#21201F" : "#FFFFFF"};
    stroke-width: 0.3
  }
`;

const BreadCounter = ({ icon: Icon, amount, status }) => {
  return (
    <BreadCounterContainer status={status}>
      <Icon />
      <p>{amount}</p>
    </BreadCounterContainer>
  );
}

export default BreadCounter;